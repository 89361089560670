<template>
  <div class="row justify-content-between h-100">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 justify-content-between align-items-center bg-primary text-white" >
      <PersonalMenu :user="user"></PersonalMenu>
    </div>
    <div class="col-lg-9 col-xl-9">
      <div class="d-flex flex-column pt-0">
        <div class="row align-items-center mt-10">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column px-10">
                <h1 class="card-label h1 font-weight-bolder">{{$t("PSACTIVITIES.PS")}}</h1>
                <h2 class="card-label h3 font-weight-bold">{{$t("PSACTIVITIES.ATTIVITA")}}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column pt-0">

        <!-- <div class="row align-items-center my-5 px-10">
          <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-3">
            <div class="card card-custom card-stretch">
              <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-3x">
                  <i class="far fa-file-alt icon-3x text-primary"></i>
                </span>
                <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="this.votes">
                  {{ this.votes.length}}
                </div>
                <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("PSACTIVITIES.IDEEVOTATE")}}</a>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-3">
            <div class="card card-custom card-stretch">
              <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-3x">
                  <i class="fas fa-heart icon-3x text-primary"></i>
                </span>
                <div class="text-dark font-weight-bolder font-size-h2 mt-3">{{user.countFollowedIdeas}} </div>
                <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"> {{$t("PSACTIVITIES.IDEESEGUITE")}}</a>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-3">
            <div class="card card-custom card-stretch">
              <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-3x">
                  <i class="fas fa-vote-yea icon-3x text-primary"></i>
                </span>
                <div class="text-dark font-weight-bolder font-size-h2 mt-3"> {{user.valueVotes1ForIdeas}}</div>
                <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("PSACTIVITIES.VOTIESPRESSI")}}</a>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-3">
            <div class="card card-custom card-stretch">
              <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-3x">
                  <i class="fas fa-wallet icon-3x text-primary"></i>
                </span>
                <div class="text-dark font-weight-bolder font-size-h2 mt-3"> {{user.valueVotes4ForIdeas}} €</div>
                <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("PSACTIVITIES.BUDGETDONATO")}}</a>
              </div>
            </div>
          </div>
        </div> -->

        <div class="row align-items-center my-5 px-10">
          <div class="col-xl-12">
            <b-tabs class="w-100">

              <b-tab active >
                <template v-slot:title>
                  <div class="p-3 border-bottom border-2 border-bottom-primary">{{$t("PSACTIVITIES.TUOIPROCESSI")}}</div>
                </template>
                <b-card-text>
                  <div  class="col-12 px-0 pt-1"  v-if="!organizationProcesses.find(process => isProcessOpen(process.endDate))">{{$t("PSACTIVITIES.NOPROCESSIATTIVI")}}</div>

                  
                  <div
                      :class="{'row mt-5 mb-5': isProcessOpen(process.endDate)}"
                      v-for="process in organizationProcesses"
                      :key="process.id"
                  >
                    <div class="col-12 p-0 shadow-sm ribbon ribbon-clip ribbon-right"  v-if="isProcessOpen(process.endDate)">
                      <div class="ribbon-target p-2 font-size-sm" style="top: 6px;">
                        <span
                            class="ribbon-inner"
                            v-bind:class="{
                            'bg-primary': process.status.label == $t('PARTIALSPROCESSI.INCORSO'),
                            'bg-warning': process.status.label == $t('PARTIALSPROCESSI.INATTIVAZIONE'),
                            'bg-danger': process.status.label == $t('PARTIALSPROCESSI.CONCLUSO')
                          }"
                        ></span><span class=" text-uppercase font-weight-bolder">{{ process.status.label }}</span>
                      </div>

                      <div class="card w-100 shadow-sm">
                        <div class="card-body px-4 py-2">
                          <div :class="['row w-100 align-items-center', process.logo ? '' : 'py-3']">
                            <div class="d-sm-none d-md-block col-md-2 col-lg-2 col-xl-3">
                              <img
                                  v-if="process.logo"
                                  :src="process.logo.uri"
                                  class="pt-5 pb-5 pr-0 pl-5 w-100"
                                  alt=""
                              />
                            </div>
                            <div class="d-flex col-sm-7 col-md-7 col-lg-7">
                              <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                                <div class="text-muted">{{process.createdDate | moment("D/MM/YYYY")}}</div>
                                <h4 class="font-weight-bold mb-0">{{ process.title }}</h4>
                              </div>
                            </div>
                            <div class="d-flex mt-10 justify-content-end">
                              <router-link
                                  :to = "{ name: 'processDetail', params: { id: process.id } }"
                                   tag="button"
                                   class="w-100"
                              >
                                <button class="btn btn-secondary text-uppercase font-weight-bolder py-2 px-6 rounded-0">{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</button>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 px-0 pt-1"  v-if="organizationProcesses.length == 0">
                    {{$t("PSACTIVITIES.NOPROCESSI")}}
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>

        <div class="row align-items-center my-5 px-10">
          <div class="col-xl-12">
            <b-tabs class="w-100">

              <b-tab active >
                <template v-slot:title>
                  <div class="p-3 border-bottom border-2 border-bottom-primary">{{$t("PSACTIVITIES.TUOIVOTI")}}</div>
                </template>
                <b-card-text>
                  <div
                      class="row mt-5 mb-5"
                      v-for="vote in votes"
                      :key="vote.id"
                  >
                    <div class="col-12" v-if="votes.length > 0">
                      <div class="card w-100 shadow-sm">
                        <div class="card-body py-6">
                          <div class="row w-100 align-items-center">
                            <div class="col-sm-6 col-md-8 col-lg-8">
                              <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                                <div class="text-muted">{{vote.idea.createdDate | moment("D/MM/YYYY")}}</div>
                                <h4 class="font-weight-bold mb-0">{{vote.idea.localizations.title}}</h4>

                              </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4">
                              <div class="row justify-content-end mt-2">
                                <div class="d-flex flex-column justify-content-start">                                  
                                </div>
                                <div class="d-flex flex-column justify-content-end">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" v-else>
                      {{$t("PSACTIVITIES.FOLLOWERS")}}
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="p-3 border-bottom border-2 border-bottom-primary"> {{$t("PSACTIVITIES.TUEPROPOSTE")}}</div>
                </template>
                <b-card-text>
                  <div
                    class="row mt-5 mb-5"
                    v-for="(item) in userIdeas"
                    :key="item.id"
                  >
                      <div class="col-12" v-if="userIdeas.length > 0">
                         <div class="card w-100 shadow-sm  ribbon ribbon-clip ribbon-right">
                           <div class="ribbon-target p-3" style="top: 12px;">
                             <span class="ribbon-inner" :class="{
                               'bg-success': item.ideaStatus.id == 3,
                               'bg-danger': item.ideaStatus.id == 1
                             }"></span>
                             <span class=" text-uppercase font-weight-bolder">{{ (item.ideaStatus.id == 3) ? $t("PSACTIVITIES.PUBBLICATA") : $t("PSACTIVITIES.INBOZZA") }}</span>
                           </div>
                          <div class="card-body py-6">
                            <div class="row w-100 align-items-center">
                              <div class="col-sm-6 col-md-8 col-lg-8">
                                <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                                  <div class="text-muted">{{item.createdDate | moment("D/MM/YYYY")}}</div>
                                  <h4 class="font-weight-bold mb-0">{{item.title}}</h4>
                                </div>
                              </div>
                              <div class="col-sm-6 col-md-4 col-lg-4">
                                <div class="row justify-content-end mt-2">
                                  <div class="d-flex flex-column justify-content-start">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                           <div class="card-footer">
                             <div class="col-12">
                               <div class="row justify-content-end">
                                 <div class="d-flex flex-column justify-content-start">

                                   <router-link
                                       :to="{
                                        name: 'processModuleIdeaDetail',
                                        params: {
                                          id: item.id,
                                          process_id: item.processId,
                                          phase_id: item.processPhaseId,
                                          module_id: item.process2ModuleId,
                                        }
                                      }"
                                   >
                                     <div class="d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center">
                                       <span class="d-none d-sm-block">{{$t("PSACTIVITIES.VISUALIZZA")}}</span> <i class="fas fa-angle-double-right pb-1 pr-0  ml-1"></i>
                                     </div>
                                   </router-link>

                                 </div>
                               </div>
                             </div>
                           </div>
                        </div>
                      </div>
                      <div class="col-12" v-else>
                        {{$t("PSACTIVITIES.FOLLOWERS")}}
                      </div>
                    </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>

      </div>
    </div>
    <div class="d-none d-sm-block col-1 justify-content-between align-items-center">
      <div class="d-flex flex-column mt-10 mr-5 align-items-center">
        <router-link to="">
          <a
            href="#"
            class="mb-10 btn btn-icon w-auto d-flex btn-lg px-2"
            @click.prevent="alertInfo"
            id="pop-info"
          >
            <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
               </span>
            </span>
          </a>
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import PersonalMenu from "@/view/layout/common/PersonalMenu.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "personalSpaceActivities",
  components: {
    PersonalMenu,
  },
  data() {
    return {
      userIdeas: [],
      organizationProcesses: [],
      userProcessIds: '',
      groups: [],
      user: [],
      votedIdeas: [],
      votes: []
    }
  },
  computed:{
    ...mapGetters([
      "currentUser",
      "getOrganization"
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.currentUser.name || this.currentUser.surname ? `${this.currentUser.name} ${this.currentUser.surname}` : this.currentUser.username }
    ]);
  },
  created(){
    this.getUser();
  },
  methods: {
    isProcessOpen(endDate) {
      if(endDate) {
        endDate = new Date(endDate)
        let currentDate = new Date()
        return endDate > currentDate
      }
      return true

    },
    getProcessStatus(item) {
      let d0 = +moment(new Date()).format("YYYYMMDD");
      let d1 = +moment(item.startDate).format("YYYYMMDD");
      let d2 = +moment(item.endDate).format("YYYYMMDD");

      if (typeof d2 === 'number' && d0 > d2) {
        // return "Concluso";
        return {
          "label":  this.$t("PARTIALSPROCESSI.CONCLUSO"),
          "color": "btn-light-danger"
        };
      } else if (typeof d1 === 'number' && d0 < d1) {
        // return "In attivazione";
        return {
          "label": this.$t("PARTIALSPROCESSI.INATTIVAZIONE"),
          "color": "btn-light-warning"
        };
      } else {
        // return "In corso";
        return {
          "label": this.$t("PARTIALSPROCESSI.INCORSO"),
          "color": "btn-light-primary"
        };
      }
    },

    getUser() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("users-expanded/" + this.currentUser.id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.user = res.data;            
            let ids = '';
            this.user.processes.forEach( p => {
              ids = ids + p.id + ',';
            });
            this.userProcessIds = ids.slice(0, -1);            
            this.getVotes();
            this.getIdeas();
            this.getProcess();
            this.$isLoading(false);
            resolve(1);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    getProcess() {
      // let qs = "?id.in=" + this.userProcessIds + "&organizationId.equals=" + this.getOrganization.data.id + "&fks_=istatus";
      let qs = "?organizationId.equals=" + this.getOrganization.data.id;
      return new Promise((resolve, reject) => {
        let endpoint =  this.generateUrl('processes', true);        
        ApiService.get(this.$apiResourceOpen, endpoint + qs)
          .then( res => {
            // this.organizationProcesses = res.data;
            for (const item of res.data) {
              item.status = this.getProcessStatus(item);              
              this.organizationProcesses.push(item);
            }
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    getIdeas() {
      let qs = "?authorId.equals=" + this.user.id + "&organizationId.equals=" + this.getOrganization.data.id + "&fks_=istatus";

      return new Promise((resolve, reject) => {

        let endpoint =  this.generateUrl('ideas', true);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + qs)
        .then(res => {

          this.userIdeas = res.data;
        })
        .catch(() => {
          reject(0);
        });
      });
    },

    getVotes() {

      let qs = "?voterId.equals=" + this.user.id;

      return new Promise((resolve, reject) => {

        var endpoint = this.generateUrl('idea-votes', false);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + qs)
          .then(res => {
            // const allVotes= res.data;
            // const uniqueVotes = Array.from(new Set(allVotes.map(a => a.idea.id)))
            //   .map(id => {
            //     return allVotes.find(a => a.idea.id === id)
            //   })
            // this.votes = uniqueVotes;

            // Filtro le idee votate dall'utente
            const ideeVotate = res.data.filter( (i) => {
              return i.voter.id === this.user.id
            });            

            // Filtro le localizzazioni delle idee votate dall'utente in funzione della lingua attiva
            const ideeVotateLoc = ideeVotate.map( obj => {
              let loc = obj.idea.localizations.find (obj1 => {
                return obj1.locale === i18nService.getActiveLanguage()
              })
              obj.idea.localizations = loc;
              return obj;
            })            
            this.votes = ideeVotateLoc;
          })
          .catch(() => {
            reject(0);
          });
      });
    },

     getModule(module_id, i) {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("process-modules-expanded/" + module_id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
            .then(res => {
              this.user.idea2Votes[i].idea.process2phaseId = res.data.processPhaseId

              let canModifyVote = false;
              for(let index = 0; index < res.data.processVotes.length; index++) {

                  if ( res.data.processVotes[index].open ) {
                    canModifyVote = true;
                  }
              }

              this.user.idea2Votes[i].idea.canModifyVote = canModifyVote;
              this.votedIdeas.push(this.user.idea2Votes[i].idea);

              return res.data;
            })
            .catch(() => {
              reject(0);
            });
      });
     },
    alertInfo(){
      Swal.fire({
        title: this.$t("PSACTIVITIES.ALERTINFO"),
        html:this.$t("PSACTIVITIES.ALERTDETAIL"),
        icon: "info",
        confirmButtonClass: "btn btn-primary"
      });
    }
  }
};
</script>
